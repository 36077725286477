
<template>
  <div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="is_loading">
    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                    <Skeleton></Skeleton>
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-12 col-xl-12">


                <div class="row" v-for="(v,k) in 7" :key="k">
                  <div class="col-md-5 col-lg-5 col-xl-5">
                    <div class="news-wrapper" style="height:100%">
                      <a href=""
                        title="">
                          <Skeleton height="80%"></Skeleton>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7 col-lg-7 col-xl-7 p-left">
                    <div class="news-description">
                      <h3>
                        <a href=""
                          title="">
                            <Skeleton > </Skeleton>
                        </a>
                      </h3>
                      <h4 class="the-title">
                        <a href=""
                          title="">

                          <Skeleton height="100%"></Skeleton>
                      

                        </a>
                      </h4>
                      <div class="post-link text-left">
                        <span class="entry-date mrr-20">
                          <i class="fa fa-calendar mrr-10 text-primary-color"></i>
                          <Skeleton width="50px"></Skeleton>
                        </span>
                        <span class="entry-date">
                          <i class="fa fa-eye mrr-10 text-primary-color"></i>
                          <Skeleton width="50px"></Skeleton>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              

              </div>
            </div>
          </div>
         
        </SkeletonTheme>
  </div>

   <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else >
          <section class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n titleh2n_news" v-if="has_cate">
                  {{mycate.name}}
                </h2>
                 <h2 class="titleh2n titleh2n_news" v-else>
                    Không có danh mục này.
                </h2>

              </div>
            </div>
          </section>
          <div class="container container-my-news" v-if="my_data!=null && has_cate">
            <div class="row justify-content-center">
              <div class="col-md-12 col-lg-12 col-xl-12">


                <div class="row"  v-for="(news,key) in paginationslice(my_data, 10, page)" :key="key">
                  <div class="col-md-5 col-lg-5 col-xl-5">
                    <div class="news-wrapper">
                      <a :href="$router.resolve({name:'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"
                        :title="news.name">
                        <img :alt="news.name" :src="myurl+''+news.picture" class="img-full lazy" >
                      </a>
                    </div>
                  </div>
                  <div class="col-md-7 col-lg-7 col-xl-7 p-left">
                    <div class="news-description">
                      <h3>
                        <a :href="$router.resolve({name:'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"
                          :title="news.name" >
                          {{news.name}}
                        </a>
                      </h3>
                      <h4 class="the-title">
                        <a :href="$router.resolve({name:'news.detail',params:{locale:$i18n.locale,slug:news.slug}}).href"
                          :title="news.name" >

                           {{news.description}}
                        </a>
                      </h4>
                      <div class="post-link text-left">
                         <span class="entry-date mrr-20" v-if="news.dateCreate!=undefined">
                          <i class="fa fa-calendar mrr-10 text-primary-color"></i>
                          {{news.dateCreate | viewDate(news.createdTime)}}
                        </span>
                        <span class="entry-date mrr-20" v-else>
                          <i class="fa fa-calendar mrr-10 text-primary-color"></i>
                          {{news.modifiedTime | viewDate(news.createdTime)}}
                        </span>
                        <span class="entry-date">
                          <i class="fa fa-eye mrr-10 text-primary-color"></i>
                          {{news.viewer}} {{$t('news.view')}}
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              
               
               

              </div>


           


            </div>
 <div class="row">
 <TopNews :Header="2"></TopNews>
  </div>
            <div class="row">
              <div class="col-xl-12">
                 <nav class="pagination-nav pdt-30" v-if="my_data.length>10">
                    

                    <VuePagination
                    :page-count="Math.floor(my_data.length / 10)"
                    :page-range="3"
                    :margin-pages="2"
                    :click-handler="changePage"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :container-class="'pagination-list text-center'"
                    :page-class="'page-numbers'"
                  >
                  </VuePagination>
                  </nav>

              </div>
            </div>
          </div>

        </div>



</template>


<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import VuePagination from "vuejs-paginate";
  import axios from 'axios';

  import TopNews from "@/components/default/TopNews.vue";
export default {
  
 
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
      mycate:null,

      has_cate:true,
      is_loading:true,
      page: 1,

      meta:{
        title:"",
        description:""
      }
      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    VuePagination,
    TopNews
    
  },
  created(){

        axios.get(window.my_api+"api/newscate/get-newscate-by-slug?slug="+this.$route.params.slug,
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
              .then( (res)=>{
                  
                  if(res.status==200){

                       this.mycate=res.data.data;
                      
                       
                           if(this.mycate!=null){
                          
                             this.meta={
                                title:this.mycate.name,
                                description:this.mycate.name
                             }
                            axios.get(window.my_api+"api/news/get-news-by-newscate?cate_slug="+this.$route.params.slug,
                             {
                                             headers: {
                                               language: this.$i18n.locale
                                             }
                              })
                          .then( (res)=>{
                              
                              if(res.status==200){

                                   this.my_data=res.data.data;
                                                        
                              }else {
                                console.log("ERROR",res);
                              }
                               

                            });  
                        }else{
                          this.has_cate=false;
                         
                        }
                          this.is_loading=false;
                  }else {
                    console.log("ERROR",res);
                  }
                   

                });

           
          
      
  },
  methods:{
   
    paginationslice(data, pagesize, page) {
      return data.filter((value, n) => {
        return n >= pagesize * (page - 1) && n < pagesize * page;
      });
    },
    changePage(e) {
      this.page = e;
    }
  },
  metaInfo(){
     return {
        title:this.meta.title,
        description:this.meta.description
     }
  },
   filters: {
        viewDate: function(string,createdtime) {
            if(string==null || string==undefined){
              return createdtime.substring(0,10);
            
            }          
            var today = new Date(string);

            var date = today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear();

            return date;

        }
      }
}
</script>
